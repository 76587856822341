import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import { GlobalStyle } from "./global-styles";
import ParticleOptions from "./components/particles-options/particle-options.component";
import Header from "./components/header/header.component";
import FloatingBackButton from "./components/floating-back-button/floating-back-button.component";
import ScrollToTopAuto from "./components/scroll-to-top-auto/scroll-to-top-auto.component";
import ErrorFallback from "./components/error-fallback/error-fallback.component";
import Loader from "./components/loader/loader.component";
import Footer from "./components/footer/footer.component";
import ScrollUpButton from "./components/scroll-up-button/scroll-up-button.component";

const HomePage = lazy(() => import("./routes/home-page/home-page.component"));
const ProjectsPage = lazy(() =>
  import("./routes/projects-page/projects-page.component")
);
const AboutMePage = lazy(() =>
  import("./routes/about-me-page/about-me-page.component")
);
const ContactPage = lazy(() =>
  import("./routes/contact-page/contact-page.component")
);
const ADIManager = lazy(() =>
  import("./routes/individual-project-pages/adi-manager/adi-manager.component")
);

const HappyHaddock = lazy(() =>
  import(
    "./routes/individual-project-pages/happy-haddock/happy-haddock.component"
  )
);
const BreakfastAndAfterSchoolClub = lazy(() =>
  import(
    "./routes/individual-project-pages/breakfast-and-after-school-club/breakfast-and-after-school-club.component"
  )
);
const ImageCreditsPage = lazy(() =>
  import("./routes/image-credits-page/image-credits-page.component")
);
const DataProtectionPage = lazy(() =>
  import("./routes/data-protection-page/data-protection-page.component")
);

const App = () => (
  <>
    <GlobalStyle />
    <ParticleOptions />
    <Header />

    <ScrollToTopAuto />
    <ScrollUpButton />
    <FloatingBackButton />

    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/projects/*" element={<ProjectsPage />} />
          <Route path="/projects/adi-manager" element={<ADIManager />} />
          <Route path="/projects/happy-haddock" element={<HappyHaddock />} />
          <Route
            path="/projects/breakfast-and-after-school-club"
            element={<BreakfastAndAfterSchoolClub />}
          />
          <Route path="/about-me" element={<AboutMePage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/image-credits" element={<ImageCreditsPage />} />
          <Route path="/data-protection" element={<DataProtectionPage />} />
        </Routes>
        <Footer />
      </Suspense>
    </ErrorBoundary>
  </>
);

export default App;
